<template>
  <div id="modelindex">
    <!--内容区域布局-->
    <LiefengContent>
      <!--功能名称，定义则显示-->
      <template v-slot:title>用户分布统计</template>
       <!--左则工具条-->
      <template v-slot:toolsbarLeft>
       
      </template>
       <!--右则工具条-->
      <template v-slot:toolsbarRight>
      </template>
       <!--内容区域-->
      <template v-slot:contentArea>
        <div style="display:flex;align-items:stretch;height:150px;justify-content: space-around;margin:20px 0;">
         <Card  class="cardsty" style="background:#2d8cf0;">
        <div>
            <h1>{{totalData.totalData.totalData}}</h1>
            <p>累计用户数</p>
        </div>
         </Card>
             <Card  class="cardsty" style="background:#2d8cf0;">
        <div>
            <h1>{{totalData.overlaydata.familyData.current}}</h1>
            <p>已入驻网上家园数</p>
        </div>
         </Card>
             <Card  class="cardsty">
        <div>
            <h1>{{totalData.totalData.yesterdayData}}</h1>
            <p>昨日新增数</p>
        </div>
         </Card>
             <Card class="cardsty">
        <div>
            <h1>{{totalData.totalData.weekData}}</h1>
            <p>本周新增数</p>
        </div>
         </Card>
             <Card class="cardsty">
        <div>
            <h1>{{totalData.totalData.monthData}}</h1>
            <p>本月新增数</p>
        </div>
         </Card>
            
        </div>
         <div style="display:flex;align-items:stretch;height:200px;justify-content: space-around;margin:20px 0;">
         <Card  class="cardsty cardbg" style="width: 23%">
        <div>
             <h1>{{totalData.overlaydata.zoneData.current}} / {{totalData.overlaydata.zoneData.total}}</h1>
          <h1>{{parseInt(totalData.overlaydata.zoneData.current*100 / totalData.overlaydata.zoneData.total)}}%</h1>
            <p>用户的区覆盖数</p>
        </div>
         </Card>
             <Card  class="cardsty cardbg" style="width: 23%">
        <div>
              <h1>{{totalData.overlaydata.streetData.current}} / {{totalData.overlaydata.streetData.total}}</h1>
             <h1>{{parseInt(totalData.overlaydata.streetData.current*100 / totalData.overlaydata.streetData.total)}}%</h1>
            <p>用户的街道覆盖数</p>
        </div>
         </Card>
           <Card  class="cardsty cardbg" style="width: 23%">
        <div>
              <h1>{{totalData.overlaydata.communityData.current}} / {{totalData.overlaydata.communityData.total}}</h1>
             <h1>{{parseInt(totalData.overlaydata.communityData.current*100 / totalData.overlaydata.communityData.total)}}%</h1>
            <p>用户的社区覆盖数</p>
        </div>
         </Card>
             <Card class="cardsty cardbg" style="width: 23%">
        <div>
            <h1>{{totalData.overlaydata.realNameData.current}} / {{totalData.overlaydata.realNameData.total}}</h1>
             <h1>{{parseInt(totalData.overlaydata.realNameData.current*100 / totalData.overlaydata.realNameData.total)}}%</h1>
            <p>已实名用户覆盖数</p>
        </div>
         </Card>
           
        </div>

         <Card style="width:98%;margin:50px 1% 0 1%;">
      <Row type="flex" justify="space-between">
        <Col span="6" style="text-align:center;font-size:16px;font-weight:bold;" v-for="(item,index) in chartData" :key="index">{{item.columns[1]}} <pie style="margin-top:-20px;" :data="item" :settings="settingsparam" :legend="{show:false}" ></pie></Col>
             </Row>
          </Card>

      </template>
    </LiefengContent>

  </div>
</template>
<script>
//@route('/statisticsuser')
import LiefengContent from "@/components/LiefengContent3.vue";
import "echarts/lib/component/title";
import pie from 'v-charts/lib/pie.common';
import LiefengTable from "@/components/LiefengTable.vue";
export default {
  name: "statisticsuser",
  components: {
    LiefengContent,
    pie,
    LiefengTable,
  },
  data() {
   return{
      chartData:[],
      talbeColumns: [],
      tableData: [],
      totalData:{
       totalData: {},
       overlaydata:{   
            zoneData:{},
            streetData: {},
            communityData: {},
            realNameData: {},
            familyData: {}
        }
    },  
      settingsparam:{radius:80}

   }
  },
  created() {
  },
  mounted() {
    this.loadTotalData();
  },
  methods: {
     loadTotalData(){//加载全局汇总
        this.$Message.loading({
                    content: '数据加载中...',
                    duration: 0
                });
        this.$get("/voteapi/api/pc/userStatistics/userColumn").then((res) => {
          if(res&&res.data){
            this.totalData.totalData=res.data.totalData;
            this.totalData.overlaydata = res.data.coverData;
          }
          this.$Message.destroy();
          this.loadPublishData();
        })
        .catch((err) => {
          this.$Message.destroy();
        });
     },
     loadPublishData(){//读取栏目数
        this.$get("/voteapi/api/pc/userStatistics/userProportionColumn").then((res) => {
          if(res&&res.dataList){
           this.chartData=res.dataList;
          }
          this.$Message.destroy();
        })
        .catch((err) => {
          this.$Message.destroy();
        });
     },
    exportData () {
          this.$refs.lftable.exportCsv({
              filename:this.title+ '分类栏目数'
          });
     }
  },
};
</script>

<style lang="less" scoped>
.tooldiv{
  width:60px;line-height:30px;text-align:right;white-space:nowrap;
}
.cardsty{
  min-width:200px;width:18%;background:#2db7f5;color:#fff;display:flex;align-items:center;justify-content: center;text-align: center;
}
.cardsty p{
  font-size: 16px;
  line-height:2em;
}
.cardsty h1{
  line-height:1.5em;
}
.cardbg{
  background:#edb314;height:200px;
}

</style>
